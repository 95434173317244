import React, { FC, useCallback, useMemo } from "react";

import Link from "next/link";

import { Extras_Api_ServiceOutput } from "@/graphql-schema-types.generated";
import { useSchemaLdJsonToContent } from "@/hooks";
import { dataLayerViewAndSelectItemEvent } from "@/lib/dataLayersPush";
import { formatPrice } from "@/lib/formatters/price";
import ErrorMessage from "@/uikit2/molecules/ErrorMessage";

import { StayCompliantListItemType, StayCompliantType } from "../../types";
import Subtitle from "../Subtitle";
import { useGetProductsHomepageQuery } from "./index.generated";

import styles from "./styles.module.scss";

type Props = {
  content: StayCompliantType;
  schemaProductTable: string;
};

const StayCompliant: FC<Props> = ({ content, schemaProductTable }) => {
  const { data, error } = useGetProductsHomepageQuery();
  const productTableSchema = useSchemaLdJsonToContent(
    schemaProductTable,
    "productTableSchema"
  );

  const products = useMemo(
    () =>
      Object.fromEntries(
        (data?.products?.list?.extra_services ?? []).map((p) => [p.code!, p])
      ),
    [data?.products?.list?.extra_services]
  );

  return (
    <section className={styles.container}>
      {productTableSchema}
      <div className={styles.bg1} />
      <div className={styles.bg2} />
      <div className={styles.bg3} />
      <div className={styles.content}>
        <Subtitle className={styles.title} content={content.title} />
        <p
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: content.text }}
        />

        <div className={styles.services}>
          {content.services.map((s) => (
            <Service
              key={s.title}
              item={s}
              product={products[s.product_code]}
            />
          ))}
        </div>
      </div>
      {error && <ErrorMessage message={error.message} />}
    </section>
  );
};

export default StayCompliant;

type ServiceProps = {
  item: StayCompliantListItemType;
  product:
    | Pick<
        Extras_Api_ServiceOutput,
        "price" | "price_need_calculate" | "tax_amount"
      >
    | undefined;
};

const Service: FC<ServiceProps> = ({ item, product }) => {
  const handleClickItem = useCallback(
    (clickItem: StayCompliantListItemType, price: number) => {
      dataLayerViewAndSelectItemEvent({
        action: "select_item",
        products: [{ title: clickItem.title, price }],
      });
    },
    []
  );
  const productPrice = (product?.price ?? 0) + (product?.tax_amount ?? 0);

  return (
    <Link passHref href={item.button_url} prefetch={false}>
      <a
        className={styles.service}
        onClick={() => handleClickItem(item, productPrice)}
      >
        <img
          alt={item.title}
          className={styles.serviceImage}
          height={65}
          loading="lazy"
          src={item.picture.url}
          srcSet={`${item.picture.url}, ${item.picture2x.url} 2x`}
          width={65}
        />
        <div className={styles.serviceContent}>
          <div className={styles.serviceTitle}>{item.title}</div>
          <div className={styles.serviceDescription}>{item.description}</div>
        </div>
        <div className={styles.serviceButton}>
          {item.button_text}{" "}
          {product?.price_need_calculate
            ? ""
            : product
            ? formatPrice((product.price ?? 0) + (product.tax_amount ?? 0), 0)
            : null}
        </div>
      </a>
    </Link>
  );
};
