import { ReactText } from "react";

export function formatPrice(
  value: ReactText | null | undefined,
  minimumFractionDigits = 2,
  maximumFractionDigits?: number,
  style: "currency" | "decimal" = "currency"
) {
  if (value == null || value === "") {
    return "";
  }
  return Number(value).toLocaleString("en-EN", {
    style,
    currency: "GBP",
    currencyDisplay: "symbol",
    minimumFractionDigits,
    maximumFractionDigits,
  });
}

export function formatPricePcm(value: ReactText | null | undefined) {
  if (value == null) {
    return "";
  }
  return `${formatPrice(Math.round(Number(value)), 0)} pcm`;
}

export function formatPriceRangePcm(
  val1: number | null | undefined,
  val2: number | null | undefined
) {
  if (val1 == null || val2 == null) {
    return "";
  }
  return `${formatPrice(val1, 0, 0)} - ${formatPrice(
    val2,
    0,
    0,
    "decimal"
  )} pcm`;
}

export function parseIntlNumber(
  value: string | undefined | null,
  locale = "en-EN"
) {
  //https://stackoverflow.com/questions/29255843/is-there-a-way-to-reverse-the-formatting-by-intl-numberformat-in-javascript
  // const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
  // const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
  // let reversedVal = val.replace(new RegExp('[^\\d\\${decimal}]', 'g'), '');
  // reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, 'g'), '.');
  // return Number.isNaN(reversedVal) ? 0 : reversedVal;

  if (value != null) {
    const clearedValue = value.replace(/[^\d.]/g, "").trim();
    return clearedValue !== "" ? Number(clearedValue) : "";
  }

  return "";
}

type FormatPriceMaskOptions = {
  minimumFractionDigits: number;
  maximumFractionDigits?: number;
  style?: "currency" | "decimal";
};

export const formatPriceMask =
  (options?: FormatPriceMaskOptions) => (value?: string | number | null) =>
    Number.isFinite(Number(value))
      ? formatPrice(
          value,
          options?.minimumFractionDigits,
          options?.maximumFractionDigits,
          options?.style
        )
      : "";
